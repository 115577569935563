
  import { prismicLink } from '@/utils/url'

  export default {
    name: 'AlternateGrid',
    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        },
      },
    },
    computed: {
      link() {
        return prismicLink(
          this.slice.primary.link,
          this.$prismic.asText(this.slice.primary.linkLabel)
        )
      },
    },
  }
